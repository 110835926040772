import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './views/Home/Home'
import Privacy from './views/Privacy/Privacy'
import TermsAndConditions from './views/Terms/TermsAndConditions'
import ResetPassword from './views/ResetPassword/ResetPassword'

function App() {
  return (<Router>
    {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
    <Switch>
      <Route path="/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route path="/privacy" exact>
        <Privacy/>
      </Route>
      <Route path="/terms" exact>
        <TermsAndConditions/>
      </Route>
      <Route path="/" exact>
        <Home />
      </Route>
    </Switch>
  </Router>);
}

export default App;
