import React from 'react';
import '../../assets/css/bootstrap.css';
import '../../assets/css/fontawesome-all.css';
import '../../assets/css/swiper.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/styles.css';
import '../../assets/css/terms_condition.css';
import Terms from '../../components/Terms/Terms'
import Footer from '../../components/Footer/Footer'

export default function TermsAndConditions() {
    return (<div>
      <Terms/>
      <Footer/>
  </div>);
}