import React from 'react';
import { Link } from 'react-router-dom';


export default function PrivacyPolicy() {
    return (<>
        <div class="ex-basic-1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 offset-xl-1">
                        <h2>Privacy policy</h2>

                        <p>Flamingo Limited (“Flamingo,” “we,” and “us”) respects the privacy of its users (“you”) and has developed this Privacy Policy to demonstrate its commitment
                        to protecting your privacy.
                        This Privacy Policy describes the information we collect, how that information may be used, with whom it may be shared, and your choices about such uses and disclosures.
                        We encourage you to read this Privacy Policy carefully when using our application or services or transacting business with us.
                        By using our website, application or other online services (our “Service”), you are accepting the practices described in this Privacy Policy.
                                  </p>

                        <p>If you have any questions about our privacy practices, please refer to the end of this Privacy Policy
for information on how to contact us. Please read this Privacy Policy in conjunction with our <Link to="/terms">Terms of Service</Link>.</p>

                        <h3>Information we collect about you</h3>
                        <p><strong>In General.</strong> We may collect Personal Information, including Sensitive Data,
 and other information. “Personal Information” means individually identifiable information
 that would allow us to determine the actual identity of, and contact, a specific living person.
 Sensitive Data includes information, comments or content (e.g. photographs, video, profile, lifestyle) t
 hat you optionally provide that may reveal your ethnic origin, nationality, religion and/or sexual orientation.
 By providing Sensitive Data to us, you consent to the collection, use and disclosure of Sensitive Data as permitted
 by applicable privacy laws. We may also collect your geolocation information with your consent. We may collect this
 information through a website, mobile application, or other online services. By using the Service, you are authorising
 us to gather, parse and retain data related to the provision of the Service. When you provide personal information through our
 Service, the information may be sent to servers located in the United States and countries around the world where data protection laws may differ.
 </p>

                        <p><strong>Information you provide. </strong>In order to register as a user with Flamingo, you will be asked
to sign in using your Facebook login or email address. If you sign in using Facebook, you authorise us
 to access certain Facebook account information, such as your public Facebook profile (consistent with your
 privacy settings in Facebook), your email address, interests, likes, gender, birthday, education history,
 relationship interests, current city, photos, personal description, friend list, and information about and
 photos of your Facebook friends who might be common Facebook friends with other Flamingo users. You will also
 be asked to allow Flamingo to collect your location information from your device when you download or use the Service.
 In addition, we may collect and store any personal information you provide while using our Service or in some other manner.
 This may include identifying information, such as your name, address, email address and telephone number, and, if you transact business with us,
  financial information. You may also provide us photos, a personal description and information about your gender and preferences for
   recommendations, such as search distance, age range and gender. If you chat with other Flamingo users, you provide us the content of
   your chats, and if you contact us with a customer service or other inquiry, you provide us with the content of that communication.
   </p>

                        <h3>Fair collection of personal data – type of data</h3>
                        <ul>
                            <li>Data related to your identity: The registration form must be completed in order to access the Services.
 You are required to provide some personal data when registering.</li>

                            <li>You may also provide The Company with a mobile phone number in order to receive text alerts as well
     as a step within our profile verification. In all cases, your e-mail address, and phone number shall not be visible or available to the public.</li>

                            <li>Location Data (geolocation): The Company’s primary function and goal is to enable users to find the people nearby,
                            in order to facilitate virtual or real life meet ups. You will be asked for permission to collect and process your
     geolocation in order to allow The Company to identify other users who agreed to geolocation.</li>

                            <li>Transaction Data: The Company does not collect or process any bank data. The Apple App Store and Google Play
                            Store have opted to refrain from sending certain data to mobile applications, which includes any banking and
                            financial information. Such data is collected and processed solely by the above platforms, and The Company does
      not have the option of changing this payment method.</li>

                            <li>Mobile Data: When you use the Application, The Company collects server logs which may include information
     such as IP addresses, operating systems or application crashes.</li>
                        </ul>

                        <p><strong>Use of technologies to collect information.</strong> We use various technologies to collect information
 from your device and about your activities on our Service.</p>

                        <p>
                            <strong>Information collected automatically.</strong> We automatically collect information from your browser or device when you visit our Service.
This information could include your IP address, device ID and type, your browser type and language, the operating system used by your device, access times, your mobile device’s
 geographic location while our application is actively running, and the referring website address.
</p>

                        <p><strong>Cookies and use of cookie and similar data.</strong> When you visit our Service, we may assign your
                        device one or more cookies or other technologies that facilitate personalisation to facilitate access to our Service and to
                        personalise your experience. Through the use of a cookie, we also may automatically collect information about your activity
                        on our Service, such as the pages you visit, the time and date of your visits and the links you click. If we advertise, we (or third parties)
                        may use certain data collected on our Service to show you Flamingo advertisements on other sites or applications.
</p>

                        <p><strong>Pixel tags. </strong>We embed pixel tags (also called web beacons or clear GIFs) on web pages, ads, and emails. These tiny, invisible
                        graphics are used to access cookies and track user activities (such as how many times a page is viewed).
                        We use pixel tags to measure the popularity of our features and services. Ad companies also use pixel tags to measure the number of ads
displayed and their performance (such as how many people clicked on an ad).</p>

                        <p><strong>Mobile device IDs.</strong> If you're using our app, we use mobile device IDs (the unique identifier assigned to a
                        device by the manufacturer), or Advertising IDs (for iOS 6 and later), instead of cookies, to recognise you.
                        We do this to store your preferences and track your use of our app. Unlike cookies, device IDs cannot be deleted,
                        but Advertising IDs can be reset in “Settings” on your iPhone. Ad companies also use device IDs or Advertising IDs
                        to track your use of the app, track the number of ads displayed, measure ad performance and display ads that are more
   relevant to you. Analytics companies use device IDs to track information about app usage.</p>

                        <p><strong>Information collected by third-parties for advertising purposes.</strong> We may allow service providers,
                        advertising companies and ad networks, and other third parties to display advertisements on our
                        Service and elsewhere. These companies may use tracking technologies, such as cookies or web beacons,
                        to collect information about users who view or interact with their advertisements. We do not provide
   any non-masked or non-obscured personal information to third parties.</p>

                        <h3>How we use the information we collect</h3>
                        <p><strong>In General.</strong> We may use information that we collect about you to:</p>

                        <ul>
                            <li>deliver and improve our products and services, and manage our business;</li>
                            <li>manage your account and provide you with customer support;</li>
                            <li>perform research and analysis about your use of, or interest in, our or others’ products, services, or content;</li>
                            <li>communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest
                                     to you either from us or other third parties;</li>
                            <li>develop, display, and track content and advertising tailored to your interests on our Service and other sites, including providing
                                    our advertisements to you when you visit other sites;</li>
                            <li>website or mobile application analytics;</li>
                            <li>verify your eligibility and deliver prizes in connection with contests and sweepstakes;</li>
                            <li>enforce or exercise any rights in our Terms of Use; and</li>
                            <li>perform functions or services as otherwise described to you at the time of collection.</li>
                            <li>pass on to businesses who we feel in our absolute discretion provide that provide services that would services that would be of interest to you</li>

                        </ul>

                        <p><strong>Job applicants. </strong>If your Personal Information is submitted when applying for a position with our company, the information will be used solely in connection with
    considering and acting upon your application. We may retain your Personal Information, but only for the purpose of considering your
    application for current or future available positions. This information, which may include your name and contact information,
    prior education and experience, and other information you provide to us, may be shared with third-party service providers retained by us to collect,
     maintain and analyse candidate submissions for job postings.</p>
                        <p>
                            In all circumstances, we may perform these functions directly or use a third party vendor to perform these
                            functions on our behalf who will be obligated to use your personal information only to perform services for us
                            Also, if you access our Service from a third party social platform, such as Facebook, we may share non-personal information
 with that platform to the extent permitted by your agreement with it and its privacy settings.</p>

                        <h3>With whom we share your information</h3>

                        <p>Information Shared with Other Users. When you register as a user of Flamingo, your Flamingo profile will be
                        viewable by other users of the Service. Other users (and in the case of any sharing features available on Flamingo,
                        the individuals or apps with whom a Flamingo user may choose to share you with) will be able to view information
                        you have provided to us directly or through Facebook, such as your Facebook photos, any additional photos you upload,
                        your first name, your age, approximate number of miles away, your personal description, and information you have in common
                        with the person viewing your profile, such as common Facebook friends and likes. If you swipe right or use voting buttons to
                        say “yes” to a person, you will be able to chat with that person if they swipe right or use voting buttons to say “yes” to you, e.g.,
       when you have a “match.” If you chat with your match, of course your match will see the content of your chat.</p>

                        <p><strong>Personal information. </strong>We do not share your personal information with others except as indicated in this Privacy Policy or
                             when we inform you and give you an opportunity to opt out of having your personal information shared. We may share personal information with:</p>

                        <p><strong>Service providers.</strong>W We may share information, including personal and financial information, with third parties that perform certain services on
                            our behalf. These services may include fulfilling orders, providing customer service and marketing assistance, performing business and sales analysis,
                             ad tracking and analytics, member screenings, supporting our Service functionality, and supporting contests, sweepstakes, surveys and other features offered
                             through our Service. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use
                              such information for any other purposes.</p>

                        <p><strong>Other Flamingo group businesses.</strong>We may share information we collect, including your profile and personal information such as your name
                            and contact information, photos, interests, activities and transactions on our Service with other Flamingo group companies.</p>

                        <p><strong>Other Situations.</strong>We may disclose your information, including personal information:</p>

                        <ul>
                            <li>In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights;
  to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.</li>

                            <li>When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity,
                            suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees,
  or others; to comply with applicable law or cooperate with law enforcement; or to enforce our Terms of Use or other agreements or policies.</li>

                            <li>In connection with a substantial corporate transaction, such as the sale of our business, a divestiture,
 merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</li>

                            <li>To our employees, consultants, contractors, subcontractors, professional advisors,
 insurers or agents if we believe that this would be beneficial for Flamingo or its group companies.</li>


                        </ul>

                        <p><strong>Aggregated and/or non-personal information.</strong> We may use and share non-personal information we collect under any of the above circumstances.
     We may also share it with third parties to develop and deliver targeted advertising on our Service and on websites or applications of third parties,
      and to analyse and report on advertising you see. We may combine non-personal information we collect with additional non-personal information collected
      from other sources. We also may share aggregated, non-personal information, or personal information in hashed, non-human readable form, with third parties,
      including advisors, advertisers and investors, for the purpose of conducting general business analysis, advertising, marketing, or other business purposes.
      For example, we may engage a data provider who may collect web log data from you (including IP address and information about your browser or operating system)
      , or place or recognise a unique cookie on your browser to enable you to receive customised ads or content. The cookies may reﬂect de-identified demographic or other data
      linked to data you voluntarily have submitted to us (such as your email address), that we may share with a data provider solely in hashed, non-human readable form.
      We may also share your geolocation information in de-identified form with Flamingo companies and third parties for the above purposes. To opt out of the sharing of your
       geolocation information, please discontinue use of the Flamingo application.</p>

                        <h3>Do Not Track disclosure</h3>

                        <p>Do Not Track (“DNT”) is a privacy preference that users can set in their web browsers. DNT is a way for users to inform websites and services that they do
                        not want certain information about their webpage visits collected over time and across websites or online services. We are committed to providing you with
                        meaningful choices about the information we collect and that is why we provide the opt-out links above. However, we do not recognise or respond to any DNT signals,
      as the Internet industry works toward defining exactly what DNT means, what it means to comply with DNT, and a common approach to responding to DNT.</p>

                        <h3>Third-party websites</h3>

                        <p>There are a number of places on our Service where you may click on a link to access other websites that do not operate under this Privacy Policy.
                        For example, if you click on an advertisement on our Service, you may be taken to a website that we do not control. These third- party websites
                        may independently solicit and collect information, including personal information, from you and, in some instances, provide us with information about
                        your activities on those websites. We recommend that you consult the privacy statements of all third-party websites you visit by clicking on the
       “privacy” link typically located at the bottom of the webpage you are visiting..</p>

                        <h3>How you can access and correct your information</h3>

                        <p>If you have a Flamingo account, you have the ability to review and update your personal information within the Service
                        by opening your account and going to settings. Applicable privacy laws may allow you the right to access and/or request
    the correction of errors or omissions in your personal information that is in our custody or under our control. Our Privacy Officer will assist you with the access request.</p>

                        <p>We will respond to requests within the time allowed by all applicable privacy laws and will make every effort to
    respond as accurately and completely as possible. Any corrections made to personal information will be promptly sent to any organisation to which it was disclosed.</p>

                        <p>In certain exceptional circumstances, we may not be able to provide access to certain personal information we hold. For security purposes,
                        not all personal information is accessible and amendable by the Privacy Officer. If access or corrections cannot be provided,
    we will notify the individual making the request within 30 days, in writing, of the reasons for the refusal.</p>

                        <h3>Data retention</h3>

                        <p>If you delete or disable your account, we will retain your data for a period of 3 years for analytical purposes and recordkeeping integrity,
                        as well as to prevent fraud, collect any fees owed, enforce our terms and conditions, take actions we deem necessary to protect the integrity
                        of our Services or our users, or take other actions otherwise permitted by law. After this period personally identifiable information associated
                        with the account such as email address/phone number/photos will be anonymised/masked. To preserve the integrity of the platform the anonymised
                        member data will continue to be stored. In addition, if certain information has already been provided to third parties as described
      in this Privacy Policy, retention of that information will be subject to those third parties' policies.</p>

                        <h3>Data deletion</h3>

                        <p>We record the exact time you request for your account to be deleted.</p>

                        <p>The moment you request deletion, your account, profile, and photos are taken offline and are not shown to other Flamingo members.</p>

                        <p>Apart from a confirmation of this request, no further emails will be sent to you from us.</p>

                        <p>Once up-to three years has elapsed, we will then permanently delete your personally identifiable information (such as photos/email address/phone number/profile).
     Only once this has taken place are you able to create a brand new account using your existing email address or phone number.</p>

                        <h3>Data deletion reasoning</h3>

                        <p>Unfortunately we may have a request for data from law enforcement with regards to conversations on a particular members account many months after they have left our service.
                        This may be as a result of a new or ongoing investigation. It is not satisfactory for us to immediately delete all information on request by a
     user as this may prevent such evidence being supplied.</p>

                        <p>Block history is maintained for this period to prevent users repeatedly harassing other members after being blocked. This is in order to protect all members.
     Should this be immediately deleted it would allow a blocked member to rematch with a member in order to message/harass them again.</p>

                        <p>Phone number/email address/login credentials are retained during this period to prevent users creating multiple duplicate accounts and hence prevent harassment of other members.</p>

                        <h3>Duplicate accounts</h3>

                        <p>Having multiple accounts is against our Terms Of Service. Retaining the email address and phone number during this period allows us to prevent such abuse of our services.</p>

                        <h3>Privacy Policy consent and agreement</h3>

                        <p>Please note that the above policy is defined herein our Privacy Policy and <Link to="/terms">Terms of Service</Link>. We record the exact time when each member consents to these policies.</p>

                        <h3>Your choices about collection and use of your information</h3>

                        <p>You can choose not to provide us with certain information, but that may result in you being unable to use certain features of our Service because such information
                        may be required in order for you to register as user; purchase products or services; participate in a contest, promotion, survey, or sweepstakes; ask a question;
      or initiate other transactions.</p>

                        <p>Our Service may also deliver notifications to your phone or mobile device. You can disable these notifications by going into “App Settings”
     on the app or by changing the settings on your mobile device.</p>

                        <p>You can also control information collected by cookies. You can delete or decline cookies by changing your browser settings. Click “help” in the toolbar of most browsers for instructions.</p>

                        <h3>How we protect your personal information</h3>

                        <p>We take security measures to help safeguard your personal information from unauthorised access and disclosure. However, no system can be completely secure.
                        Therefore, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information, chats,
                        or other communications will always remain secure. Users should also take care with how they handle and disclose their personal information and should avoid sending personal
     information through insecure email.</p>

                        <p>You agree that we may communicate with you electronically regarding security, privacy, and administrative issues, such as security breaches.
    We may post a notice on our Service if a security breach occurs. We may also send an email to you at the email address you have provided to us.</p>

                        <p>Information you provide about yourself while using our Service</p>

                        <p>We provide areas on our Service where you can post information about yourself and others and communicate with others. Such postings are governed by our <Link to="/terms">Terms of Service</Link>.
                        Also, whenever you voluntarily disclose personal information on publicly-viewable pages, that information will be publicly available and can be collected and used by others.
                        For example, if you post your email address, you may receive unsolicited messages. We cannot control who reads your posting or what other users may do with the information
                        you voluntarily post, so we encourage you to exercise discretion and caution with respect to your personal information. The Company cannot be held liable for data published
      on its platform and disclosed by a Member.</p>

                        <h3>Children's privacy</h3>

                        <p>Although our Service is a general audience Service, we restrict the use of our service to individuals age 18 and above. We do not knowingly collect, maintain,
     or use personal information from children under the age of 18.</p>

                        <h3>Onward transfer and consent to international processing</h3>

                        <p>We are a growing corporation with users and operations in multiple countries, including the European Union. We have developed data practices designed to assure information
                        is appropriately protected but we cannot always know where personal information may be accessed or processed. While our primary data centres are in the United States,
                        we may transfer personal information or other information to our offices outside of the United States. In addition, we may employ other companies and individuals to perform functions on our behalf.
                        If we disclose personal information to a third party or to our employees outside of the United States, we will seek assurances that any information we may provide to them is safeguarded
                        adequately and in accordance with this Privacy Policy and the requirements of applicable privacy laws and obtain confirmation that such information is safe guarded in accordance with the
       requirements of applicable privacy laws.</p>

                        <p>If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer
                        of your personal data, including sensitive data, by Flamingo from your region to countries which do not have data protection laws that provide the same level of protection
                        that exists in countries in the European Economic Area, including the United States. By providing your personal information, you consent to any transfer and processing in accordance
     with this Policy.</p>

                        <h3>No Rights of Third Parties</h3>

                        <p>This Privacy Policy does not create rights enforceable by third parties or require disclosure of any personal information relating to users of the website.</p>

                        <h3>Changes to this Privacy Policy</h3>

                        <p>We will occasionally update this Privacy Policy to reflect changes in the law, our data collection and use practices, the features of our Service,
                        or advances in technology. When we post changes to this Privacy Policy, we will revise the “last updated” date at the top of this Privacy Policy, which will
                        be posted on the Services under “Settings” and also on Flamingo.com, and you should regularly check for the most recent version, which is the version that applies.
                        If we make any material changes to this Privacy Policy, we will notify you of the changes by reasonable means, which could include notifications through the Services or via email.
                        Please review the changes carefully. Your continued use of the Services following the posting of changes to this policy will mean you consent to and accept those changes.
      If you do not consent to such changes you can delete your account by following the instructions under Settings.</p>

                        <h3>How to contact us</h3>

                        <p>If you have any questions about this Privacy Policy, please contact us by email as follows:</p>

                        <p>info@Flamingo.com</p>


                    </div>
                </div>
            </div>
        </div>
    </>
    );
}