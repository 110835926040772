import React, { useState } from 'react';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";

import { BASE_URL } from '../../variables/constants';

import logo from '../../assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(20)
    },
    appLogo: {
        height: '20vmin',
        pointerEvents: 'none'
    },
    textField: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
        width: '30vw'
    },
    '@media (max-width: 1024px)': {
        textField: {
            width: '90vw'
        }
    },
    alert:{
        width: '28vw',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    button: {
        display: 'block',
        margin: '0 auto',
        textTransform: 'none',
        padding: theme.spacing(1),
    }
}));

export default function ResetPassword() {
    const classes = useStyles();
    const [values, setValues] = useState({
        newPassword: '',
        confirmPassword: '',
        showNewPassword: false,
        showConfirmPassword: false,
        formMessage:'',
        severity:'success'
        
    });
    let { token } = useParams();

    // const [formMessage,setFormMessage] = useState('');
    // const [severity, setSeverity] = useState('success');


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = (prop) => {
        var flag = (prop == "showNewPassword") ? !values.showNewPassword : !values.showConfirmPassword;
        setValues({ ...values, [prop]: flag });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    

    const submitForm = () => {
        if (!values.newPassword) {
            setValues({ ...values, severity: 'error', formMessage: 'New Password is required' });
        } else if (!values.confirmPassword) {
            setValues({ ...values, severity: 'error', formMessage: 'Confirm Password is required' });
        } else if (values.confirmPassword != values.newPassword) {
            setValues({ ...values, severity: 'error', formMessage: 'Passwords do not match!' });
        } else {
            setValues({ ...values, submitting: true });
            fetch(`${BASE_URL}users/reset-password`, {
                method: 'post',
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                },
                body: `token=${token}&new_password=${values.newPassword}&confirm_password=${values.confirmPassword}`
            })
                .then((response) => {
                    
                    setValues({ ...values, submitting: false });
                    

                    response.json().then((data) => {
                        console.log(JSON.stringify(data));

                        if (data.success) {
                            setValues({newPassword: '',
                            confirmPassword: '',
                            showNewPassword: false,
                            showConfirmPassword: false, severity: 'success', formMessage: 'Password change was successful, login to your app' }); 

                            setTimeout(()=>{
                               window.location="/";
                            },3000);
                        } else {
                            setValues({ ...values, severity: 'error', formMessage: data.message });
                        }
                    });
                }
                )
                .catch(function (err) {
                    setValues({ ...values, submitting: false });
                    console.log('Fetch Error :-S', err);
                });

        }
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column"
                justify="center"
                alignItems="center" spacing={3}>

                <Grid item xs={12}>
                    <img src={logo} className={classes.appLogo} alt="logo" />
                </Grid>

                {
                    values.formMessage&&<Grid item xs={12}>
                    <Alert className={classes.alert} severity={values.severity}>{values.formMessage}</Alert>
                </Grid>
                }
                
                <form noValidate autoComplete="off">
                    <Grid item xs={12}>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-new-password" color="secondary" size="large">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-new-password"
                                label="New Password"
                                type={values.showNewPassword ? 'text' : 'password'}
                                value={values.newPassword}
                                onChange={handleChange('newPassword')}
                                color="secondary"
                                required="true"
                                helperText="New password is required."
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("showNewPassword")}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                            <InputLabel htmlFor="outlined-confirm-password" color="secondary">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="outlined-confirm-password"
                                label="Confirm Password"
                                type={values.showConfirmPassword ? 'text' : 'password'}
                                value={values.confirmPassword}
                                onChange={handleChange('confirmPassword')}
                                color="secondary"
                                required="true"
                                helperText="Confirm password is required."
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword("showConfirmPassword")}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={() => submitForm()} className={classes.button} variant="contained" color="secondary" disabled={values.submitting}>
                            {values.submitting?'Sending...':'Reset Password'}
                        </Button>
                    </Grid>

                </form>

            </Grid>
        </div>
    );
}