import React, { useState } from 'react';
import highlights_screen_shot from '../../assets/images/highlights.png';
import event_screen_shot from '../../assets/images/events.png';
import discussion_screen_shot from '../../assets/images/discussions.png';


import '../Features/features.css';

export default function Features() {
    return (
        <>
            <div className="basic-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="image-container">
                                <img className="img-fluid device-mockup" src={highlights_screen_shot} alt="alternative" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="text-container">
                                <h2>Create your highlights</h2>
                                <p>Swipe, match and chat with interesting people from all over the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="basic-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="text-container">
                                <h2>Friends Discussions</h2>
                                <p>Discuss about interesting topics. Love,Romance,Relationships, etc. Nothing is off limits.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="image-container">
                                <img className="img-fluid device-mockup" src={discussion_screen_shot} alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="basic-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="image-container">
                                <img className="img-fluid device-mockup" src={event_screen_shot} alt="alternative" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="text-container">
                                <h2>Find Amazing Events</h2>
                                <p>See what is happening. Who is attending. Browse and attend local events.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         

        </>
    );
}