import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import discover_screenshot from '../../assets/images/discover.png';
import google_playstore_img from '../../assets/images/google-play-badge.png';
import apple_playstore_img from '../../assets/images/ic_app_store.png';
import instagram_logo from '../../assets/images/ic_insta.png';
import facebook_logo from '../../assets/images/ic_fb.png';
import twitter_logo from '../../assets/images/ic_twitter.png';
import google_plus_logo from '../../assets/images/ic_google.png';

export default function Footer() {
    return (
        <>

            <div className="footer">
                <div className="row">
                    <div className="col-lg-12 footer-banner">
                        <div className="text-container">
                            <h1 className="footer-header">Download Our Application </h1>



                            <a className="playstore-logo" href="#your-link"><img src={apple_playstore_img} /></a>
                            <Link className="playstore-logo" to={{ pathname: "https://play.google.com/store/apps/details?id=com.myflamingoes&hl=en_US" }} target="_blank"><img src={google_playstore_img} /></Link>

                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 offset-md-3">        
                            <h6>About Flamingo</h6>
                            <p className="p-small">Flamingo is a geosocial networking and online dating application that allows users to anonymously swipe to like or dislike other profiles based on their photos, a small bio, and common interests. Once two users have "matched", they can exchange messages.</p>
                        </div>
                    </div>

                    <div className="row">
                        
                            <div className="col-md-6 offset-md-3 social-media-links">
                                <a href="#your-link">
                                  <img src={instagram_logo} />
                                </a>
                                <a href="#your-link">
                                  <img src={facebook_logo} />
                                </a>
                                <a href="#your-link">
                                  <img src={twitter_logo} />
                                </a>
                                <a href="#your-link">
                                  <img src={google_plus_logo} />
                                </a>
                            </div>
                           
                      
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-2">
                            <a href="contact.html">Contact</a>
                        </div>
                        <div className="col-lg-2"><Link to="/privacy">Privacy Policy</Link></div>
                        <div className="col-lg-2"><Link to="/terms">Terms & Conditions</Link></div>
                        <div className="col-lg-2"><a href="contact.html">Disclaimer</a></div>
                        <div className="col-lg-4">
                            <p className="p-small">Copyright © 2020 <a href="https://myflamingoes.com">Flamingo Inc</a> All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}