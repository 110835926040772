import React from 'react';
import '../../assets/css/bootstrap.css';
import '../../assets/css/fontawesome-all.css';
import '../../assets/css/swiper.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/styles.css';
import NavBar from '../../components/NavBar/NavBar'
import Header from '../../components/Header/Header'
import Features from '../../components/Features/Features'
import Footer from '../../components/Footer/Footer'

export default function Home() {
    return (<div>
      <Header/>
      <Features/>
      <Footer/>
  </div>);
}