import React from 'react';
import '../../assets/css/bootstrap.css';
import '../../assets/css/fontawesome-all.css';
import '../../assets/css/swiper.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/styles.css';
import NavBar from '../../components/NavBar/NavBar'
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy'
import Footer from '../../components/Footer/Footer'

export default function Privacy() {
    return (<div>
      <PrivacyPolicy/>
      <Footer/>
  </div>);
}