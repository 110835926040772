import React from 'react';


export default function Terms() {
    return (
        <>
            <div class="ex-basic-1">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-10 offset-xl-1">
                            <h1>Terms & conditions</h1>
                            <h3>Flamingo’s Terms and Conditions of Use</h3>
                            <h4>The Summary</h4>
                            <p>Hello. You should read these terms and conditions in full because they apply every time you visit Flamingo
                                 and use any of its features. However, just in case you ever need a reminder of the main points, here’s a quick summary:</p>

                            <ol>
                                <li>If you are under the age of 18 then unfortunately you can’t use Flamingo yet because it’s a meeting place for adults only.</li>

                                <li>We’re not responsible for anything that you post or say while you are on Flamingo and we don’t monitor the content of the site,
                                     but if we do see or someone alerts us that you have posted something that we think is inappropriate then we are allowed at our discretion to remove it.</li>

                                <li>If you post any content that actually belongs to someone else and they get annoyed (or even call in their lawyers), 
                                    we are not in the firing line. You have to take responsibility for what you post.</li>

                                <li>Please refer to our Community Guidelines and Safety Tips for additional Dos and Don’ts, and information on staying safe.</li>

                                <li>If you are concerned about how your data is being used by Flamingo then please refer to our Privacy Policy, 
                                    which explains how we treat your personal data and protect your privacy when you use Flamingo. By using Flamingo, you agree that we can use such data in accordance with our Privacy Policy. Our website and apps also use cookies or similar technologies which we tell you more about here. You may set your browser and your mobile settings to block cookies and local storage devices, but if you do so, you may not be able to access the features that Flamingo offers.</li>
                            </ol>


                            <h3>The full legal bit</h3>

                            <p>Flamingo is a social media website and app designed as a forum for meeting new people, a place to have discussions and to share photos, news and information. It is intended to be a fun place to visit and it is important to us (and for you) that it remains a safe and friendly environment so you agree that you will only use Flamingo in a manner consistent with its purpose and which is in accordance with these terms and conditions, as well as the Flamingo Community Guidelines and Safety Tips (the “Terms”). When we mention Flamingo, we mean www.Flamingo.com, together with affiliated websites and applications.</p>

                            <p>The Terms constitute a binding legal agreement between you as user (“you”) and the Flamingo Group (“we” or “us”).</p>

                            <p>The Terms apply whenever you visit Flamingo, whether or not you have chosen to register with us, so please read them carefully. By accessing, using, registering for or receiving services offered on Flamingo you are accepting and agreeing to be bound by the Terms.</p>

                            <h3>IF YOU DO NOT ACCEPT AND AGREE TO THE TERMS THEN YOU MUST NOT ACCESS OR USE THE APP OR SITE.</h3>

                            <h2>1. Use of the app and site and rules relating to Content</h2>
                            <h3>Who can use Flamingo?</h3>
                            <p>Flamingo is a meeting place for adults. You may only use Flamingo, including any of its features, or become a registered member if you are 18 years old or older (or the age of majority in the country in which you reside if that happens to be greater than 18).</p>

                            <p>You warrant that you have the right, authority and capacity to enter into and be bound by the Terms and that by using Flamingo you will not be violating any law or regulation of the country in which you are resident. You are solely responsible for your compliance with all applicable local laws and regulations.</p>

                            <p>You further warrant that you have not been convicted of, nor are subject to any court order relating to assault, violence, sexual misconduct or harassment.</p>

                            <h3>What kind of content can I post or upload on Flamingo?</h3>
                            <p>You are able to post or upload all kinds of things on Flamingo, including photographs, emails, messages and other content (“Content”).</p>

                            <p>There are some rules about what is acceptable though, so when you are using Flamingo you may not post, send or upload any Content which:</p>
                            <ol>
                                <li>contains expletives or language which could be deemed offensive or is likely to harass, upset, embarrass, alarm or annoy any other person;</li>
                                <li>is obscene, pornographic or otherwise may offend human dignity;</li>
                                <li>is abusive, insulting or threatening, or which promotes, depicts or encourages violence, self-harm, suicide, racism, sexism, hatred or bigotry;</li>
                                <li>encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which in itself constitutes committing a criminal offence;
is defamatory;</li>
                                <li>relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other websites or premium line telephone numbers);</li>
                                <li>involves the transmission of “junk” mail or “spam”;</li>
                                <li>impersonates a person, company or brand with the intent to deceive or confuse others;</li>
                                <li>contains any spyware, adware, viruses, corrupt files, worm programmes or other malicious code designed to interrupt, damage or limit the functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal data whether from Flamingo or otherwise;</li>
                                <li>itself, or the posting of which, infringes any third party’s rights (including, without limitation, intellectual property rights and privacy rights);</li>
                                <li>shows another person where such Content was created or distributed without that person’s knowledge, and without that person having been afforded an opportunity to refuse such creation or distribution; or</li>
                                <li>contains images of children, even if you are also in the photo, or endangers minors.</li>
                            </ol>

                            <p>
                                Please use your common sense when picking the Content that you choose to post on, upload or send via Flamingo
                                because you are solely responsible for, and bear all liability in relation to, such Content. If you repeatedly infringe our rules,
                                you may be prevented from using Flamingo and we may disable your account or block you from further use of Flamingo. We may remove
                                any Content you submit to Flamingo if we believe it violates the Terms or we are required to do so by applicable law.
</p>


                            <h4>Are there any rules relating to personal data like my email address?</h4>

                            <p>You may not display any personal contact or banking information on your individual profile page (“Profile”)
                            whether in relation to you or any other person (for example, names, home addresses or postcodes, telephone numbers, email addresses,
                            URLs, credit/debit card or other banking details, or place of work). If you do choose to reveal any personal data about yourself to other users,
                            whether via email or otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details about yourself to third parties
    online as you would under any other circumstances.</p>

                            <h4>What about other people’s personal data, can I use it?</h4>
                            <p>
                                You may only use other Flamingo users’ personal data to the extent that your use of it matches Flamingo’s purpose of allowing
                                people to meet one another. You may not use other users' information for commercial purposes, to spam, to harass, or to make unlawful threats.
                                Flamingo reserves the right to terminate your account, or prevent access to its features if you misuse other users' information.
                            </p>

                            <h4>Some of my friends want to see all the people I’m chatting with. Can I tell them my password and let them log on as me?</h4>

                            <p>The short answer is no. Only you are authorised to access your account with Flamingo. You must not share your password with anyone or let anyone else access your account as this jeopardises the security of all of the Content and personal data that you have submitted to Flamingo. You are responsible for keeping your password secret and secure.
                            If you don’t, Flamingo is not liable for any unauthorised access to your account. If you suspect that someone has gained access
                            to your account, you must let us know immediately by visiting Feedback page and telling us of your suspicions or concerns.
                            You must also immediately change your password.
    Flamingo reserves the right to terminate your account if you violate our rules on keeping your password secure.</p>

                            <h4>Who can see the Content that I put on Flamingo?</h4>
                            <p>
                                When you upload Content to Flamingo it can be accessed and viewed by the general public. If you do not want such Content to be viewed by others,
                                then you can opt to use the private folders and select the person to whom you wish to give access. If you still do not want such Content to be viewed by others, then you should not upload it to Flamingo.
                                We reserve the right (without obligation) at our sole discretion and without giving you notice, to remove or edit, limit or
                                block access to any Content that you upload or submit to Flamingo without any incurring liability to you. We have no obligation to
                                display any Content that you submit to Flamingo, nor to check the accuracy or truthfulness of any Content submitted to Flamingo,
nor to monitor your use or the use of other users of Flamingo.</p>

                            <h4>Anything else I need to know?</h4>
                            <p>
                                When you use Flamingo you agree to the Terms, and in return we grant you a limited, personal, non-exclusive and non-transferable
                                licence to use and to upload Content and to use Flamingo solely for your personal use. Apart from this licence, you have no other rights
                                in the use of the site, app, or its Content or features, and you may not modify, edit, copy, reproduce, create derivative works of, reverse engineer,
                                alter, enhance or in any way exploit any of the site or Content in any manner. If you breach any of the Terms, the above licence will terminate
                                automatically and you must immediately destroy any downloaded or printed Content.
</p>

                            <h2>2. Ownership of Content</h2>
                            <h4>Once I have uploaded Content on Flamingo, do I still own it?</h4>
                            <p>Yes (provided you are the rightful owner in the first place — please see our other rules regarding posting Content that doesn’t belong to you).</p>

                            <p>Please note though that by posting, uploading or sending Content on Flamingo you represent and warrant to us that:</p>

                            <ol>
                            <li>you are either the exclusive author or owner of that Content and in all cases you agree that you waive any and all moral rights relating to that Content (including, without limitation, any rights to be identified as the author);</li>
                            <li>you have been granted a licence that allows you to use, post or upload the Content; or</li>
                            <li>you have obtained appropriate consent or authority to use, post or upload such Content.</li>
                            </ol>
                            
                            <p>Please note that by posting, uploading or sending Content on Flamingo you represent and warrant to us that you have the right to do so, and automatically grant to us a non-exclusive, royalty free, perpetual, worldwide licence to use such Content in any way (including, without limitation, editing, copying, modifying, adapting, translating, reformatting, creating derivative works from, incorporating into other works, advertising, distributing and otherwise making available to the general public such Content, whether in whole or in part and in any format or medium currently known or developed in the future).</p>

                            <p>We may assign and/or sub-license the above licence to our affiliates and successors without any further approval by you.</p>

                            <p>We have the right to disclose your identity to any third party who is claiming that any Content posted or uploaded by you to our app or site constitutes a violation of their intellectual property rights or of their right to privacy or any other law.</p>

                            <h4>Who does the rest of the Content on Flamingo belong to then?</h4>
                            <p>Us! All text, graphics, user interfaces, trademarks, logos, sounds and artwork on Flamingo are owned, controlled or licensed by us and are protected by copyright, trademark and other intellectual property law rights.</p>

                            <h4>Can I use any of the Content that doesn’t belong to me?</h4>
                            <p>Other than in relation to Content submitted by you to Flamingo, you do not have any rights in relation to the Content on Flamingo and you agree that you will not use any Content in any manner which may infringe ours or any third party’s rights. This means that you agree that you will not copy, modify, adapt, distribute, publish or sell all or any part of the Flamingo site or the Content contained on it (other than the Content submitted by you) to anyone else.</p>

                            <h2>3. Paid for services</h2>
                            <h4>What can I buy on Flamingo?</h4>
                            <p>Flamingo offers you the opportunity to pay for certain services offered on the site. Flamingo’s premium services include various opportunities to find and meet additional people, to customise your profile and to boost your profile so more people find you.  All of these premium services can be purchased after you register, and you may subscribe to the premium services for various lengths of time.</p>



                            <p>You can change your Auto Top-up at anytime in your payment settings on your profile page. The pricing may vary due to a number of factors, such as (but not limited to) promotional offers, loyalty bonuses and other discounts.</p>

                            <p>Paid for services are not available in sanctioned countries.</p>

                            <h4>How do I renew my premium services with Flamingo?</h4>

                            <p>Once you have requested a Flamingo premium service, you authorise Flamingo to charge your chosen payment method.
                            If you want to cancel or change your payment method at any time, you can do either via the payment settings option under
                            your profile or by contacting your mobile service provider. If your chosen payment method is via your mobile service provider,
                            then please check with them about their payment terms, as their payment terms will govern how payments to Flamingo are made as well
                            as how such payments may be changed or cancelled. Your subscription to Flamingo’s premium services will automatically renew until you decide
                            to cancel, and if you have enabled the Auto Top-up feature, your account will automatically be topped up until you decide to disable this feature.
   </p>

                            <h4>Can I transfer any of my paid for services to another Flamingo user?</h4>
                            <p>If you elect to partake in any of the games, premium features or range of services designed to improve your Flamingo user experience,
                            you agree to use these services for yourself and not to transfer (for free or as a sale) to or purchase, or sell such services from other
                            Flamingo users. Flamingo reserves the right not to honour any paid for services where you have attempted to transfer those services to
                            another use or you have attempted to buy those services from another user. Flamingo will only provide paid for services to you if you are the
   person to whom it was issued.</p>

                            <h3>Do my credits last forever?</h3>
                            <p>No. We reserve the right to expire any unused credits expire 6 months (unless the credits are purchased through Itunes).
                            If you delete your account or if your account is terminated by us due to breach of these Terms, you will lose any accumulated credits.
                            If you receive free or promotional credits, we may expire them at any time. Credits are not redeemable for any sum of money or monetary value
from us unless we agree otherwise in writing.</p>

                            <p>Please refer to the Service Conditions on your ‘top-up’ page for further details relating to your payments.</p>

                            <h2>4. Access to the app and site</h2>
                            <h4>Do you guarantee that Flamingo will be up and running at all times?</h4>
                            <p>Unfortunately we can’t guarantee that because sometimes we have to carry out maintenance to the app or site or they may be affected by a fault or circumstances which are
                            outside our control, so Flamingo is provided on an “as is” “as available” basis. No warranty is given about the quality, accuracy, functionality,
                            availability or performance of Flamingo and we reserve the right to suspend, withdraw, amend, modify or vary the service provided on Flamingo without
 notice and without incurring any liability to you.</p>


                            <h3>What about mobile access?</h3>
                            <p>You are responsible for making all the necessary arrangements to ensure you can access Flamingo (including, but not limited to Internet provider and mobile internet provider fees and, any other charges associated with such access). We shall not be held responsible for any reduced functionality you may encounter as result of or in connection with accessing Flamingo through mobile services or any similar service currently known or developed in the future.</p>

                            <p>By accessing Flamingo or agreeing to receive messages or notifications from Flamingo through your mobile phone and/or any other connected media device, you accept that you may incur charges from your internet or mobile service provider. We shall not under any circumstances be liable for such charges.</p>

                            <h3>I’m not registered and I can’t seem to access some Content or features on the app or site. Why is that?</h3>
                            <p>Non-registered users are able to access only that portion of Flamingo that is publicly available. They will not have a Profile but may have limited ability to upload any Content. The extent of registered users’ access to Flamingo will depend on certain criteria relating to their Profile being met. We may change or update the criteria from time to time without prior notice and at our discretion.</p>

                            <h2>6. Termination of use by you</h2>
                            <h4>They say that you can have too much of a good thing… what do I do if I want to “unregister” myself from Flamingo?</h4>
                            <p>
                                If you have registered on Flamingo, you can terminate your registration at any time by going to the ‘Settings’ control
                                on Flamingo when you are logged in and choosing the ‘delete profile’ link. We save your profile information in case
                                you later decide to restore your account. Many users deactivate their accounts for temporary reasons and in doing so
                                expect us to maintain their information until they return to Flamingo. You will therefore be able to restore your account
                                and your profile in its entirety within 30 days of de-activating it. Once your registration is terminated, we have the right
                                to delete any related Content that you have submitted or uploaded on to Flamingo. For further details, please refer to our Privacy Policy.
    </p>

                            <p>Content that you have uploaded to Flamingo, other than your Profile (such as comments or email messages), may still appear on Flamingo following termination of your registration.</p>

                            <h2>7. Abuse/Complaints</h2>
                            <h4>Someone is not obeying the Terms, who do I tell?</h4>
                            <p>You can report any abuse or complain about Content on Flamingo by contacting us via Feedback page, outlining the abuse and or complaint. You can also report a user directly from a profile, by clicking the ‘Report Abuse’ link on their profile, choosing the reason for the complaint and providing us with any additional information you think fit.</p>

                            <p>We will always try our best to help resolve any issue that you may encounter with our service. If your complaint or dispute remains unresolved, however, you may be eligible to use the European Commission’s online dispute resolution platform here.</p>

                            <h2>8. Privacy Policy</h2>
                            <h4>Do you have guidelines in place about what you can do with my personal data?</h4>
                            <p>We process information in accordance with our Privacy Policy which is incorporated into these Terms and Conditions of Use. Please read the terms of our Privacy Policy prior to using Flamingo. By using Flamingo, you agree to such processing and you warrant that all data provided by you is true, correct and accurate.</p>

                            <h2>9. Links</h2>
                            <h4>Are the links on the app or site anything to do with Flamingo?</h4>
                            <p>Flamingo may contain links to other sites, resources and purchase opportunities provided by third parties. These links are provided to you for your information only. If you access these links you may be directed to third party sites. These third party sites will have their own terms of use and privacy policies, which may differ from the Terms. The display of links to third party sites does not constitute an endorsement by us of any of the third party content information, sites, or resources provided.</p>

                            <p>Please note that we have no control over the contents of any third party sites or resources, and we accept no responsibility for them, including (but not limited to) the third party site’s compliance with any applicable laws or regulations.</p>

                            <h2>10. Disclaimer of warranties and limitation of liability</h2>
                            <h4>I forgot the rules about conduct on here and now someone is threatening to sue me. Will your lawyers sort it out?</h4>
                            <p>No. If you act in a way that upsets other users then you have to be responsible for the consequences. We expressly disclaim any and all responsibility and liability for your conduct or the conduct of any other user of Flamingo, and expressly disclaim any liability for Content uploaded by you or by any other user.</p>

                            <h4>My friend is a lawyer and says that you can’t exclude liability for everything…</h4>
                            <p>Nothing in the Terms limits or excludes our liability for:</p>

                            <ol>
                                <li>death or personal injury caused by their proven negligence;</li>
                                <li>fraud or fraudulent misrepresentation;or</li>
                                <li>any liability which cannot be limited or excluded by law.</li>
                            </ol>



                            <p>To the fullest extent permitted by law, Flamingo expressly excludes:</p>

                            <ol>

                                <li>all conditions, representations, warranties and other terms which might otherwise be implied by statute,
                                 common law or the law of equity; and</li>
                                <li>any liability incurred by you arising from use of Flamingo, its services or these Terms, including without
                                limitation for any claims, charges, demands, damages, liabilities, losses or expenses of whatever nature and
                                howsoever direct, indirect, incidental, special, exemplary, punitive or consequential damages (however arising including negligence),
                                loss of use, loss of data, loss caused by a computer or electronic virus, loss of income or profit, loss of or damage to property,
                                wasted management or office time, breach of contract or claims of third parties or other losses of any kind or character,
                                even if Flamingo has been advised of the possibility of such damages or losses, arising out of or in connection with the use of Flamingo.
                                This limitation on liability applies to, but is not limited to, the transmission of any disabling device or virus that may infect your
                                equipment, failure or mechanical or electrical equipment or communication lines, telephone or other interconnect problems (e.g., you cannot
                                access your internet service provider), unauthorized access, theft, bodily injury, property damage, operator errors, strikes or other labor
                                problems or any act of god. in connection with Flamingo including, without limitation, any liability for loss of revenue or income,
                                loss of profits or contracts, loss of business, loss of anticipated savings, loss of goodwill, loss of data, wasted management or office
                                time and any other loss or damage of any kind, however arising and whether caused by tort (including, but not limited to, negligence),
                                   breach of contract or otherwise, even if foreseeable whether arising directly or indirectly.</li>
                            </ol>




                            <p>Flamingo’s total liability to you in respect of losses arising under or in connection with the Terms, whether in contract, tort (including negligence, breach of statutory duty, or otherwise) shall in no circumstances exceed £20.</p>

                            <p>What about the reliability of Flamingo and the information contained on it?</p>

                            <p>Flamingo has taken reasonable steps to ensure the currency, availability, correctness and completeness of the
                            information contained on Flamingo and provides that information on an "as is", "as available" basis. Flamingo does
                            not give or make any warranty or representation of any kind about the information contained on Flamingo, whether express or implied.
                            Use of Flamingo and the materials available on it is at your sole risk. Flamingo cannot be held responsible for any loss arising from
                            the transmission, use of data, or inaccurate Content posted by users. You are responsible for taking all necessary precautions to ensure that
                            any material you may obtain from Flamingo is free of viruses or other harmful components. You accept that Flamingo will not be provided uninterrupted
                            or error free, that defects may not be corrected or that Flamingo, or the server that makes it available, are free of viruses or bugs,
                            spyware, Trojan horses or any similar malicious software. Flamingo is not responsible for any damage to your computer hardware, computer software,
                            or other equipment or technology including, but without limitation damage from any security breach or from any virus, bugs, tampering, fraud, error,
                            omission, interruption, defect, delay in operation or transmission, computer line or network failure or any other technical or other malfunction. In the unlikely event
       that any defect is discovered within Flamingo, we will use every effort to repair or fix the defect free of charge, without significant inconvenience to you, as soon as practicable.</p>

                            <h2>11. Indemnity</h2>
                            <h4>I forgot the rules about conduct on here and now someone is threatening to sue YOU. What now?</h4>
                            <p>If we are sued as a result of your use of Flamingo then we have the right to defend or settle the relevant claim as we see fit.
                                 If we ask, you will co-operate fully and reasonably as required by us in the defence of any relevant claim.</p>

                            <p>
                                You agree to hold harmless and indemnify us and our officers, directors, employees, agents, representatives and licensors
                                from and against any third party claims, damages (actual and/or consequential), actions, proceedings, demands, losses,
                                liabilities, costs and expenses (including reasonable solicitors’ fees) suffered or reasonably incurred by us arising as a result of,
                                or in connection with, your access to and use of Flamingo, the uploading or submission of Content to Flamingo by you or your conduct,
                                other than in accordance with the Terms or any applicable law or regulation (“Claim”). Flamingo retains the exclusive right to settle,
                                compromise and pay any and all Claims or causes of action which are brought against us without your prior consent.
</p>

                            <h2>12. Miscellaneous</h2>
                            <h4>What about all of those standard clauses that you see at the end of most contracts? They’re the best bit!</h4>
                            <p>As you asked so nicely…</p>

                            <p>The Terms and any dispute or claim arising out of or in connection with it or its subject matter (including non-contractual disputes or claims) shall be are governed by and construed in accordance with English law.
                                In the event there is a discrepancy between this English language version and any translated copies of the Website Terms and Conditions, the English version shall prevail.</p>

                            <p>We reserve the right to modify, amend or change the Terms at any time (a “Change”). If we do this then the Changes will be posted on this page
                            and we will indicate the effective date of the updates at the bottom of the Terms. In certain circumstances, we may send an email to you notifying you of any Change.
                                 You should regularly check this page to take notice of any Changes.</p>

                            <p>If you do not accept any Change to the Terms, you should stop using Flamingo immediately. Your continued use of Flamingo following any Change constitutes your acceptance of the Change and you will be legally bound by the new updated Terms.</p>

                            <p>If, for any reason, any of the Terms are declared to be illegal, invalid or otherwise unenforceable by a court of a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable,
                                it shall be severed and deleted from the Terms and the remainder of the Terms shall survive, remain in full force and effect and continue to be binding and enforceable.</p>

                            <p>The Terms set out the entire agreement and understanding between us and you in relation to your use of Flamingo and supersedes all previous agreements, representations
                                and arrangements between us (either oral or written). Nothing in this clause shall limit or exclude any liability for fraudulent misrepresentation.</p>

                            <p>No failure or delay by us in exercising any right, power or privilege under the Terms shall operate as a waiver of such right or acceptance of any variation of the Terms and nor shall any
                                single or partial exercise by either party of any right, power or privilege preclude any further exercise of that right or the exercise of any other right, power or privilege.</p>

                            <p>Nothing in the Terms shall confer or purport to confer on any other third party, any benefit or the right to enforce any term of the Terms and the Contracts
                                (Rights of Third Parties) Act 1999 which shall not apply to the Terms.</p>

                            <h3>About us</h3>
                            <p>Flamingo is an app and site owned and operated by Flamingo Limited.</p>



                            <p>Effective date</p>
                            <p>The Terms were last updated on: 08 Dec 2020.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}