import React from 'react';
import discover_screenshot from '../../assets/images/discover.png';
import google_playstore_img from '../../assets/images/google-play-badge.png';
import apple_playstore_img from '../../assets/images/ic_app_store.png';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <header id="header" className="header">

            <nav className={`navbar navbar-expand-lg  navbar-dark`}>
                <div className="container">
                    <a className="navbar-brand logo-image"><img src={logo} alt="alternative" /></a>

                </div>
            </nav>
            <div className="container">

                <div className="row">
                    <div className="col-lg-8">
                        <div className="text-container">
                            <h1 className="intro-header">Its never to late to fall in love. </h1>
                            <p className="p-large">Flamingo is the best app where you can find your soul mates.</p>


                            <a className="playstore-logo" href="#your-link"><img src={apple_playstore_img} /></a>
                            <Link className="playstore-logo" to={{ pathname: "https://play.google.com/store/apps/details?id=com.myflamingoes&hl=en_US" }} target="_blank"><img src={google_playstore_img} /></Link>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="header-screenshot">
                            <img src={discover_screenshot} />
                        </div>
                    </div>

                </div>


            </div>
        </header>

    );
}